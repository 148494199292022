import { ROUTES } from '@/controllers/router/router.contants';

interface GetConsultationLinkOptions {
  shouldRedirectToConsultationSurvey: boolean;
  courseSlug: string;
  studentSource?: string;
}

interface GetApplicationLinkOptions {
  shouldRedirectToApplicationSurvey: boolean;
  courseSlug: string;
  studentSource?: string;
}

export class CourseLandingPageModuleHelper {
  static getConsultationLink({
    shouldRedirectToConsultationSurvey,
    courseSlug,
    studentSource,
  }: GetConsultationLinkOptions) {
    const queryKeys = {
      courseSlug,
      studentSource,
    };

    const filteredQueryKeys = this.filterUndefinedValues(queryKeys);

    if (shouldRedirectToConsultationSurvey) {
      return ROUTES.consultation.signUpSurvey(filteredQueryKeys);
    }

    const queryParams = new URLSearchParams(filteredQueryKeys);

    return `${ROUTES.consultation.index}?${queryParams.toString()}`;
  }

  static getApplicationLink({
    shouldRedirectToApplicationSurvey,
    courseSlug,
    studentSource,
  }: GetApplicationLinkOptions) {
    if (!shouldRedirectToApplicationSurvey) {
      return `${ROUTES.auth.signUp}?courseSlug=${courseSlug}`;
    }

    const queryKeys = {
      courseSlug,
      studentSource,
    };

    const filteredQueryKeys = this.filterUndefinedValues(queryKeys);

    return `${ROUTES.application.signUpSurvey(filteredQueryKeys)}`;
  }

  private static filterUndefinedValues(obj: Record<string, any>) {
    return Object.fromEntries(
      Object.entries(obj).filter(([, value]) => value !== undefined),
    );
  }

  static getQueryParamsString(queryKeys: Record<string, any>): string {
    const filteredKeys = this.filterUndefinedValues(queryKeys);
    const queryParams = new URLSearchParams(filteredKeys);

    return queryParams.toString();
  }
}
